<template>
  <div>
    <div class="card cert-card d-flex justify-content-center">
      <div class="card-head head-section py-4">
        <div>부트페이결제</div>
      </div>
      <div v-if="step == 1"></div>

      <b-alert show variant="warning" v-if="message">{{ message }}</b-alert>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import BootPay from 'bootpay-js'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'naver',
  components: {},
  data() {
    return {
      step: 1,
      token: null,
      item: null,
      message: '',
    }
  },
  created() {
    // 전달값 받기.
    const query = this.$route.query
    this.token = query.t
    this.item_idx = query.i
    console.log(this.token, this.item)
  },
  mounted() {
    this.onBuy()
  },
  methods: {
    async onBuy() {
      if (!this.token || !this.item_idx) {
        this.message = '입력값이 잘못 되었습니다.'
        return
      }
      //  Api.item
      await axios({
        method: 'get',
        url: `/api/u/item/shop/${this.item_idx}`,
        headers: {
          authorization: this.token,
        },
      })
        .then((res) => {
          this.item = res.data
          console.log(this.item)
          this.reqBootpay()
        })
        .catch((err) => {
          this.message = err
        })
      // buy
    },
    reqBootpay(item) {
      if (item) {
        const application_id = this.$PUB.bootpay.application_id
        // const price = item.price
        // const name = item.name
        //const unique = `i_${item.idx}` // 가공
        // const user_uid = this.User.uid
        // const user_nick = this.User.nick
        // const email = ""
        const timestring = new Date().getTime()
        const account_expire_at = moment(timestring).format('YYYY-MM-DD')

        const order_id = 'o_' + timestring // 가공

        const items = [
          {
            item_name: item.name,
            qty: 1,
            price: item.price,
            unique: `i_${item.idx}`,
            item_idx: item.idx,
          },
        ]

        const payload = {
          price: item.price,
          application_id,
          name: item.name, //결제창에서 보여질 이름
          pg: 'danal',
          //method: 'phone', //결제수단, 입력하지 않으면 결제수단 선택부터 화면이 시작합니다.
          show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
          items,
          // user_info: {
          //   user_uid,
          //   user_nick,
          //   email
          // },
          order_id, //: '고유order_id_1234', //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
          params: {
            items,
            // user_nick,
            // user_uid
            // callback1: '그대로 콜백받을 변수 1',
            // callback2: '그대로 콜백받을 변수 2',
            // customvar1234: '변수명도 마음대로'
          },
          account_expire_at, //: '2020-10-25', // 가상계좌 입금기간 제한 ( yyyy-mm-dd 포멧으로 입력해주세요. 가상계좌만 적용됩니다. )
          extra: {
            start_at: null, //'2019-05-10', // 정기 결제 시작일 - 시작일을 지정하지 않으면 그 날 당일로부터 결제가 가능한 Billing key 지급
            end_at: null, //'2022-05-10', // 정기결제 만료일 -  기간 없음 - 무제한
            vbank_result: 1, // 가상계좌 사용시 사용, 가상계좌 결과창을 볼지(1), 말지(0), 미설정시 봄(1)
            quota: '0,2,3', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
            theme: 'purple', // [ red, purple(기본), custom ]
            custom_background: '#00a086', // [ theme가 custom 일 때 background 색상 지정 가능 ]
            custom_font_color: '#ffffff', // [ theme가 custom 일 때 font color 색상 지정 가능 ]
          },
        }

        //
        BootPay.request(payload)
          .error((data) => {
            //결제 진행시 에러가 발생하면 수행됩니다.
            console.log('>> error', data)
            this.$notify({ text: data.msg, type: 'error', group: 'error' })
          })
          .cancel((data) => {
            //결제가 취소되면 수행됩니다.
            console.log('>> cancel', data)
          })
          .ready((data) => {
            // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
            console.log('>> ready', data)
          })
          .confirm((data) => {
            //결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
            //주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
            console.log('>> confirm', data)
            var enable = true // 재고 수량 관리 로직 혹은 다른 처리
            if (enable) {
              BootPay.transactionConfirm(data) // 조건이 맞으면 승인 처리를 한다.
            } else {
              BootPay.removePaymentWindow() // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
            }
          })
          .close((data) => {
            // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
            console.log('>> close', data)
          })
          .done((data) => {
            //결제가 정상적으로 완료되면 수행됩니다
            //비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
            console.log('>> done', data)
            this.receipt(data)
          })
      }
    },

    receipt(payload) {
      axios({
        method: 'post',
        url: `/api/u/item/receipt`,
        headers: {
          authorization: this.token,
        },
        data: { receipt: payload },
      })
        .then((res) => {
          this.$notify({ text: res.message })
          if (res.data && res.data.receipt_url) {
            // 영수증 출력
            window.open(res.data.receipt_url, '결제 영수증', 'width=480, height=750')
          }
        })
        .catch((err) => {
          console.error(err)
          this.$notify({ text: err.error, type: 'error', group: 'error' })
        })
        .finally(() => {
          this.message = '결제되었습니다.'
        })
    },
  },
}
</script>
<style lang="scss">
.cert-card {
  width: 100vw;
  height: 100vh;
  background-color: #ffe414;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ffe943' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23fffad0' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23ffffff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23ffffff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23ffffff' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ffffff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ffffff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ffffff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23ffffff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23ffffff' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  text-align: center;

  .card-head {
    font-size: 1.4rem;
    padding: 10px 0;
  }

  .head-section {
    background: rgb(255, 230, 0);
    border-bottom: 4px solid orange;
    margin-bottom: 30px;

    .btn__close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .content-section {
    padding: 20px;
    .img__kakaopay {
      margin: 4px;
    }

    .q-field--labeled .q-field__native {
      line-height: 24px;
      padding-top: 34px;
      padding-bottom: 8px;
    }

    // .q-input {
    //   height: 70px;
    // }
    .q-field__label {
      font-weight: 400;
      color: black;
      margin-bottom: 10px;
    }
    .q-placeholder {
      color: grey;
      font-size: 1.1rem;
    }
    .btn__submit {
      background: rgb(255, 230, 0);
      padding: 5px 50px;
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
}
</style>
<style></style>
